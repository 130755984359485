import React from 'react';
import ceo from '../../assets/aboutus/ceo.png';

const Founder = () => {
  return (
    <div className="founder-wrapper">
      <div className="founder-container">
        <h2>Meet Our Founder/CEO</h2>

        <div className="founder-content">
          <div>
            <img src={ceo} alt="founder" className="founder-image" />
          </div>

          <div className="founder-context">
            <h6> Clare Byrne FMAAT</h6>
            <p>
              Clare is the founder of Red Fox Accounting, starting the business
              back in May 2015. However, Clare’s career in accounting started
              back in 2001, when at the age of 17 she started her AAT
              apprenticeship with Hardcastle Burton.
              <br /> After qualifying in practice she moved to a local firm of
              solicitors, working as the practice manager and internal
              accountant before heading back into practice in 2007, to complete
              her ACCA exams.
              <br /> As a client manager for individuals and small to medium
              sized limited companies she gained a wealth of experience and
              after having children decided to set up her own business. Clare
              has seen many changes in company and tax law come and go and has
              built Red Fox Accounting not only on her extensive knowledge but
              also her friendly and “plain English” approach to clients.
              <br /> When she is not working Clare is regularly found at the
              side of a football pitch, watching her girls play football and has
              recently revisited a childhood hobby- learning to play the piano.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founder;
