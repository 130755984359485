import React, { useState, useEffect } from 'react';
import Logo from '../../assets/redfoxLogo.png';
import CloseBtn from '../../assets/home/close.png';
import { BiPhone } from 'react-icons/bi';
import { IoMdMenu, IoMdArrowDropdown } from 'react-icons/io';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
  const [isNavOpen, getIsNavOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const handleScroll = () => {
      setScrolling(true);
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        setScrolling(false);
      }, 1000);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <nav className={`header ${scrolling ? 'scrolling' : ''}`}>
      <div className="header-container">
        <NavLink to="/">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
        </NavLink>
        <div className="menu">
          <BiPhone className="phone" />
          <a href="tel:01638 590463" className="phone-num">
            01638 590463
          </a>
        </div>
        <div className="menu">
          <div
            className="mobile-menu"
            onClick={() => getIsNavOpen((prev) => !prev)}
          >
            <IoMdMenu className="hamburger" />
          </div>
          <div className={isNavOpen ? 'show-menu' : 'hide-menu'}>
            <div className="close-btn" onClick={() => getIsNavOpen(false)}>
              <img src={CloseBtn} alt="" />
            </div>
            <ul className="mobile-nav">
              <NavLink to="/">
                <li>Home</li>
              </NavLink>
              <NavLink to="/about">
                <li>About Us</li>
              </NavLink>
              <NavLink to="/services">
                <li>Services</li>
              </NavLink>
              <NavLink to="/testimonials">
                <li>Testimonials </li>
              </NavLink>
              <NavLink to="/factsheet" hidden>
                <li>Fact Sheets</li>
              </NavLink>
              <NavLink to="/resource" hidden>
                <li>Resources</li>
              </NavLink>
              <NavLink to="/services" hidden>
                <li>News</li>
              </NavLink>
              <NavLink to="/contact">
                <li>Contact Us</li>
              </NavLink>
            </ul>
          </div>

          <ul className="desktop-nav">
            <NavLink to="/about">
              <li>About Us </li>
            </NavLink>

            {/* <NavLink to="/services"> */}
            <div className="dropdown">
              <div className="dropdown-nav not-clickable">
                <NavLink to="/services">
                  <li>Services</li>
                  <IoMdArrowDropdown fontSize="1.6rem" />
                </NavLink>
              </div>
              <ul className="submenu">
                <li>
                  <Link to="/services">Overview</Link>
                </li>
                <li>
                  <Link to="/services/business">Business start-up</Link>
                </li>
                <li>
                  <Link to="/services/compliance">Compliance services</Link>
                </li>
                <li>
                  <Link to="/services/consultancy">
                    Consultancy & Systems advice
                  </Link>
                </li>
                <li>
                  <Link to="/services/investment">Investments & pensions</Link>
                </li>
                <li>
                  <Link to="/services/support">Support services</Link>
                </li>
                <li>
                  <Link to="/services/taxation">Taxation</Link>
                </li>
              </ul>
            </div>
            {/* </NavLink> */}
            <NavLink to="/testimonials">
              <li>Testimonials </li>
            </NavLink>

            <NavLink to="/factsheet" hidden>
              <div className="dropdown">
                <div className="dropdown-nav">
                  <li>Factsheet</li>
                  <IoMdArrowDropdown fontSize="1.6rem" />
                </div>
                <ul className="submenu">
                  <li>
                    <Link to="/factsheet">Overview</Link>
                  </li>
                  <li>
                    <Link to="/capital">Capital taxes</Link>
                  </li>
                  <li>
                    <Link to="/corporate">Corporate & Business tax</Link>
                  </li>
                  <li>
                    <Link to="/general-business-tax">General business</Link>
                  </li>
                  <li>
                    <Link to="/ict">ICT</Link>
                  </li>
                  <li>
                    <Link to="/personal">Personal tax</Link>
                  </li>
                  <li>
                    <Link to="/pensions">Pensions</Link>
                  </li>
                </ul>
              </div>
            </NavLink>

            <NavLink to="/resource" hidden>
              <div className="dropdown">
                <div className="dropdown-nav">
                  <li>Resources</li>
                  <IoMdArrowDropdown fontSize="1.6rem" />
                </div>
                <ul className="submenu">
                  <li>
                    <Link to="/resources">Overview</Link>
                  </li>
                  <li>
                    <Link to="/accsend">Accsend</Link>
                  </li>
                  <li>
                    <Link to="/forms">Downloadable Forms</Link>
                  </li>
                  <li>
                    <Link to="/data">Market Data</Link>
                  </li>
                  <li>
                    <Link to="/calculators">Online Calculators</Link>
                  </li>
                  <li>
                    <Link to="/calendar">Tax Calender</Link>
                  </li>
                  <li>
                    <Link to="/rates">Tax Rates</Link>
                  </li>
                  <li>
                    <Link to="/uselinks">Useful Links</Link>
                  </li>
                </ul>
              </div>
            </NavLink>
            <NavLink to="/contact">
              <li>Contact Us</li>
            </NavLink>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
