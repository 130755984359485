import GetInTouch from "../Components/GetInTouch";
import HomeSection from "../Components/Home/HomeSection";
import Intro from "../Components/Home/Intro";
import Overview from "../Components/Home/Overview";
import Footer from "../Components/Layout/Footer";
import Header from "../Components/Layout/Header";

const Home = () => {
    return (
        <div>
            <Header />
            <Intro/>
            <Overview/>
            <HomeSection/>
          <GetInTouch/>
            <Footer/>
        </div>
    )
}

export default Home;