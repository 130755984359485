import React from 'react';
import taxCorporation from '../../assets/services/taxCorporation.png';
const Taxation = () => {
  return (
    <div className="taxation">
      <div className="taxation-hero">
        <h1>Taxation</h1>
      </div>
      <div className="overview">
        <div className="overview-container">
          <h2 className="overview-header">
            As tax advisers, our objective is
            to work closely with you to ensure you pay the minimum tax required
            by law.
          </h2>
          <p className="overview-text">
            We will help you to understand the tax implications of your actions,
            in order that you can plan ahead and conduct your affairs in a tax
            efficient way.
          </p>
          <h2 className="service-page-h3">Corporation tax services</h2>
          <img src={taxCorporation} alt="" />
          <p className="overview-text">
            Under Corporation Tax Self Assessment (CTSA), the legal
            responsibility for correctly calculating the corporation tax
            liability falls on business owners. We understand the issues facing
            owner-managed businesses and can prepare tax returns in a timely and
            efficient manner. We will also calculate your company's tax
            liability and assist with the calculation of any quarterly
            instalment payments due. The increased reporting obligations,
            investigation policies on the part of the tax authorities and
            harsher penalties for non-compliance mean that your time and
            resources can be taken up with tax administration. We will help to
            minimise corporate tax exposure and relieve the administrative
            burden of compliance with current tax legislation. Effective
            corporate tax planning can also result in significant improvements
            in your bottom line
          </p>

          <h2 className="support-header">
            Personal tax services (including sole-traders and partnerships)
          </h2>
          <p className="overview-text">
            We are specialists in income tax and capital gains tax.
          </p>

          <div id="mission-container inherit">
            <h2 className="support-header rich-text">Self Assessment</h2>
            <div className="tax-wrapper">
              <div className="taxation-image-seg1"></div>
              <div className="taxation-overlap-container">
                <p className="overview-text">
                  We have the expertise and software to take the headache out of
                  the form filling and compliance aspect of Self Assessment. We
                  can provide you with practical advice on personal tax and the
                  planning opportunities available to you. Over recent years
                  HMRC have increased the penalties for failing to file a return
                  on time and for errors. We can complete tax returns, calculate
                  any tax liability and advise you on exactly when to make
                  payments and how much to pay.
                </p>
              </div>
            </div>
            <div className="wrapper">
              <h2 className="support-header rich-text">
                Personal tax planning
              </h2>
              <div className="tax-wrapper tax-wrapper-reverse">
                <div className="taxation-image-seg2"></div>
                <div className="tax-modifier tax-mid-modifier">
                  <p className="overview-text">
                    Everyone who is subject to taxation needs professional
                    advice and support if they are to optimise their tax
                    position and ensure they meet the compliance requirements.
                    Our specialist tax team can provide you with year-round
                    advice on all aspects of personal taxation.
                  </p>
                </div>
              </div>
            </div>
            <h2 className="support-header rich-text">Insurance policy</h2>
            <div className="tax-wrapper">
              <div className="taxation-image-seg3"></div>
              <div className="tax-modifier tax-container-modifier">
                <p className="overview-text">
                  With the increased risk of such investigations it may be worth
                  considering taking out our annual insurance policy to protect
                  you and your business from unexpected costs; an investigation
                  will undoubtedly result in additional accountancy work being
                  undertaken. The amount of work will depend upon the complexity
                  of the case but the cost could be substantial.
                </p>
              </div>
            </div>

            <h2 className="support-header rich-text">
              HMRC Investigations and Enquiries
            </h2>
            <div className="tax-wrapper tax-wrapper-reverse">
              <div className="taxation-image-seg4"></div>
              <div className="tax-modifier tax-hmrc-modifier">
                <p className="overview-text">
                  Being the subject of a tax investigation or enquiry by HMRC
                  can be a stressful and worrying experience. We have a wealth
                  of experience in dealing with HMRC investigations, whether
                  they are enquiries into an individual’s Tax Return, more
                  detailed enquiries into a company’s affairs or
                  employer-related investigations into PAYE and P11d compliance.
                  If you are the subject of an investigation, we can provide
                  expert help and support.
                </p>
              </div>
            </div>

            <h2 className="support-header rich-text">VAT</h2>
            <div className="tax-wrapper">
              <div className="taxation-image-seg5"></div>
              <div className="tax-modifier tax-overlay">
                <p className="overview-text">
                  Ever changing regulations and the growing demands of HMRC mean
                  VAT compliance can be a difficult administrative process. We
                  can help to ensure that you comply with the regulations and
                  that overpayments are not made. To help you manage this
                  complex area, we provide an efficient, cost-effective VAT
                  service, which includes:
                </p>
                <ul className="about-li">
                  <li>Assistance with VAT registration</li>
                  <li>Advice on VAT planning and administration</li>
                  <li>Use of the most appropriate scheme</li>
                  <li>VAT control and reconciliation</li>
                  <li>Help with completing VAT returns</li>
                  <li>Planning to minimise future problems with HMRC</li>
                  <li>
                    Negotiating with HMRC in disputes and representing you at
                    VAT tribunals if necessary.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Taxation;
