import React from 'react';
import Pensions from '../../assets/services/investment.png';
import Business from '../../assets/services/startUp.png';
import Men from '../../assets/services/compliance.png';
import Coin from '../../assets/services/taxation.png';
import People from '../../assets/services/consultancy.png';
import GroupPeople from '../../assets/services/support.png';
import { NavLink } from 'react-router-dom';
import ServiceCard from './ServiceCard';

const cardData = [
  {
    id: 1,
    image: Business,
    title: 'Business Start up',
    description:
      ' Most people starting up in business do so because they have a good business proposition and commitment.',
    buttonLink: '/services/business',
  },
  {
    id: 2,
    image: Men,
    title: 'Compliance Service',
    description:
      'We provide a range of compliance services to support all aspects of business administration.',
    buttonLink: '/services/compliance',
  },
  {
    id: 3,
    image: Coin,
    title: 'Taxation',
    description:
      ' Helping you understand the tax implications of your actions, so that you can plan ahead.',
    buttonLink: '/services/taxation',
  },
  {
    id: 4,
    image: Pensions,
    title: 'Investment and Pensions',
    description:
      ' We make use of our wide range of professional contacts to ensure you have a suitable independent adviser.',
    buttonLink: '/services/investment',
  },
  {
    id: 5,
    image: GroupPeople,
    title: 'Consultancy & Systems Advice',
    description:
      'Your professional and personal goals are central to our consulting services.',
    buttonLink: '/services/consultancy',
  },
  {
    id: 6,
    image: People,
    title: 'Support Services',
    description:
      'The combination of your business knowledge and our support services will help your business grow.',
    buttonLink: '/services/support',
  },
];
const ServiceCards = () => {
  return (
    <div className="service-card-wrapper">
      <div className="card-container">
        {cardData.map((card) => (
          <ServiceCard
            key={card.id}
            image={card.image}
            title={card.title}
            description={card.description}
            buttonLink={card.buttonLink}
          />
        ))}
      </div>
    </div>
  );
};

export default ServiceCards;
