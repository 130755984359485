import React from 'react'

const Intro = () => {
  return (
    <div>
      <div className='service-hero'>
        <h1 >Our Services</h1>
      </div>
    </div>
  )
}

export default Intro
