import React from 'react';
import { Bounce } from 'react-reveal';

const Overview = () => {
  return (
    <>
      <div className="overview">
        <div className="overview-container">
          <h1 className="overview-header">Your business is our business</h1>
          <p className="overview-text">
            Red Fox Accounting is here to help with every aspect of your
            business from startup to retirement, and every day in between. Our
            services are tailored to suit your needs, whether that is a tax
            return for your rental property or accounts, VAT, and payroll for
            your growing Limited company. Please contact us today for a free
            consultation with a member of our friendly and approachable team.
          </p>
        </div>
      </div>
      <div className="what-we-do">
        <Bounce>
          <h2 className="what-we-do-text">OUR HISTORY</h2>
        </Bounce>
      </div>
      <div className="second-sect-overview">
        <div className="overview-container">
          <p className="overview-text">
            Red Fox Accounting was established in 2015 and today, we continue to
            build on our enviable reputation for providing excellent advice and
            first-class service to our business and personal clients alike. We
            have many clients in the local community and service businesses,
            small and large, across many sectors including:
          </p>
          <ul className="about-li">
            <li>The professionals</li>
            <li>The service sectors</li>
            <li>The construction industry</li>
            <li>Manufacturers and</li>
            <li>Retailers</li>
          </ul>
          <p className="overview-text">
            As well as general accountancy and tax skills, we also have
            individual specialist skills which enables us to provide clients
            with a first-class service as and when required. With our wealth of
            experience, we pride ourselves on delivering an unparalleled service
            to all clients, whatever their size or sector.
          </p>
        </div>
      </div>
    </>
  );
};

export default Overview;
