import React from 'react';

const MissionVision = () => {
  return (
    <div id="mission-container">
      <div className="mission-image-seg1"></div>
      <div className="mission-overlap-container">
        <h2>Our Vision and Values</h2>
        <p>
          Our vision is to be the UK's leading and most trusted accounting firm,
          creating exceptional tax and sustainable accounting services.
        </p>
      </div>
      <div className="mission-image-seg2"></div>
      <div className="mission-overlap-container">
        <h2>Our Mission</h2>
        <p>
          Our Mission is to provide our clients with superior accounting and
          related services that satisfies their needs and improves their quality
          of life; and to provide employees and owners with meaningful careers
          that include flexibility and opportunities for success – all while
          being a good corporate citizen.
        </p>
      </div>
      <div className="mission-image-seg3"></div>
      <div className="mission-overlap-container">
        <h2>Our core values</h2>
        <p>
          No mission is possible without the right tools for the job. So, how do
          we do what we do?
          <ul className="about-li">
            <li>
              Hire the best people with the top talent (check out our latest
              jobs)
            </li>
            <li>
              Offer clients real time business insights so they can stay ahead
              of the game
            </li>
            <li>Constantly develop our team and their expertise.</li>
            <li>Maintain slick and reliant efficiency levels</li>
            <li>
              Stay on top of industry news and insights, helping clients to
              understand their own business
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
}

export default MissionVision;
