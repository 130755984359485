import React from 'react';

const BusinessStartUp = () => {
  return (
    <div>
      <div className="business-hero">
        <h1>Business Startup</h1>
      </div>
      <div className="overview">
        <div className="overview-container">
          <h2 className="overview-header">
            Most people starting up in business do so because they have a good
            business proposition and the enterprise and commitment to see it
            through.
          </h2>
          <p className="overview-text">
            Most however are not experts in the legal and financial aspects of
            running a business and such matters can appear daunting.
            Fortunately, you do not need to be an expert in these fields to
            succeed in business, but you do need the support of a team of
            trained experts.
          </p>
        </div>

        <hr className="business-start-line" />
        <p
          style={{ fontWeight: "400" }}
          className="overview-text business-start-text"
        >
          We can help. Just bring us your business ideas and we will help you to
          make them a reality!
        </p>
        <hr className="business-start-line" />
        <div className="overview-container">
          <p className="overview-text">We will help you to:</p>
          <ul className="about-li">
            <li>
              Decide on the most suitable structure for your business - sole
              trader, partnership, limited liability partnership or limited
              company.
            </li>

            <li>Establish a good working relationship with your bank.</li>

            <li>
              Complete any registration procedures with Companies House and HMRC
            </li>

            <li>Deal with company secretarial issues</li>

            <li>
              Set up a recording system for your internal use and for complying
              with statutory requirements
            </li>

            <li>
              Decide on what software to use and help with installation and
              training
            </li>

            <li>
              Keep on top of bookkeeping, VAT, payroll and other accounting
              requirements.
            </li>

            <li>
              Help with all aspects of business compliance and administration.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BusinessStartUp;
