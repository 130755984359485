import React from 'react';
import consultMgt from '../../assets/services/consultMgt.webp';
import consultAdvice from '../../assets/services/consultAdvice.png';

const Consultancy = () => {
  return (
    <div className="consultancy">
      <div className="consult-hero">
        <h1>Consultancy & system Advice</h1>
      </div>
      <div className="overview">
        <div className="overview-container">
          <h2 className="overview-header">
            Your professional and personal goals are central to our consulting
            services; we take the time to get to know you better so we can offer
            the best advice.
          </h2>
          <p className="overview-text">
            Leave your financial worries to us! At Red Fox, our dedicated team
            of tax and accounting professionals ensures you have peace of mind.
            With our cost-effective solutions, managing your finances becomes
            hassle free. Get an accurate picture of your financials and make
            informed decisions about your future with our expert assistance.
          </p>
          <h2 className="service-page-h3">Management consultancy</h2>
          <img src={consultMgt} alt="" />
          <p className="overview-text">
            Our management consultancy service can review your credit control,
            stock control, budgeting and financial reporting systems and allow
            you to take full control of these key areas of your business. We can
            also help you address specific problem areas in your business such
            as gross profit, cost control and stock valuation. Effective
            management information will allow you to seize opportunities when
            they arise or identify problems quickly so you can take effective
            corrective action.
          </p>
          <h2 className="service-page-h3">System advice</h2>
          <img src={consultAdvice} alt="" />

          <h4 className="service-page-h4">Information Technology</h4>
          <p className="overview-text">
            Information technology (IT) is one of the key resources available to
            every business to achieve its short and long-term objectives.
            <br />
            Using the knowledge gained from our own systems and those designed
            for other clients, we are able to advise on IT related matters with
            first-hand experience. We can install software, provide training and
            help you to obtain the best from your chosen system.
            <br />
            Our expertise enables a complete range of services to be offered,
            including:
          </p>
          <ul className="about-li">
            <li>Software selection</li>
            <li>Installation and training</li>
            <li>Management reporting solutions </li>
            <li>Payroll systems</li>
          </ul>
          <p className="overview-text">
            If you are spending too much time writing up books, typing invoices
            and chasing debts we help to take control by computerising your
            accounts system. We can help you to make the best use of IT resource
            to produce financial information that is accurate and appropriate
            for running your business effectively. With the correct accounting
            software you will save hours of hard work leaving you more time to
            deal with the more important areas of the business.
            <br />
            In short, whether you are investing in new technology, reviewing
            existing systems or have simply outgrown your current resource, we
            can help
          </p>
        </div>
      </div>
    </div>
  );
};

export default Consultancy;
