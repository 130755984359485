import React from 'react';
// import financeGraphOnPhone from "../assets/services/financeGraphOnPhone.png";

const SupportService = () => {
  return (
    <div>
      <div className="support-service-hero">
        <h1>Support Services</h1>
      </div>
      <div className="overview">
        <div className="overview-container">
          <h2 className="overview-header">
            The combination of your business knowledge and our support services
            will help your business to grow and increase profitability.
          </h2>
          <h2 className="support-header font-weight">
            Management accounts and information
          </h2>
          <p className="overview-text">
            Obtaining regular reliable management information is vital to the
            continued success of any business. Let us work closely with you to
            get to know your business so we can make suggestions to help improve
            profits or to develop and grow your business. We can prepare your
            monthly or quarterly management accounts to ensure you always have
            reliable and accurate information on which to base your business
            decisions.
          </p>
          <h2 className="support-header font-weight">Payroll</h2>
          <p className="overview-text">
            Running a payroll can be time consuming and complicated and divert
            resources from the core activities of your business. With the
            implementation of Real Time Information, HMRC require information
            generally on or before the date of payment to the employee. We can
            help by installing payroll software and training your staff.
            Outsourcing this activity also helps relieve the pressure and we can
            offer cost-effective solutions. We are able to provide the complete
            service, whatever the size or complexity of your business, or simply
            provide support when needed.
          </p>
          <h2 className="support-header font-weight">Bookkeeping</h2>
          <p className="overview-text">
            We can help you with all your general bookkeeping requirements,
            either at your business premises or at our office. Maybe you need
            help writing up the books or just want us to add the finishing
            touches to the information in order to create your own management
            information. If you do not employ a bookkeeper then we can take up
            the role or help you recruit the right person for your business.
          </p>
          <h2 className="support-header font-weight">
            Business planning and forecast
          </h2>
          <p className="overview-text">
            Every business should plan ahead to ensure success, but daily
            business pressures often conspire to delay the strategic planning
            process. Financial forecasts are a crucial part of any business plan
            and we will ensure these are both meaningful and robust.
          </p>
          <h2 className="support-header font-weight">
            Liasing with banks and institutions
          </h2>
          <p className="overview-text">
            We offer support when liaising with banks and other financial
            institutions. Drawing on our wealth of experience we can support our
            clients through this often daunting experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SupportService;
