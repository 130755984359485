import React from 'react';
import Location from '../../assets/contactus/location.png';
import Mail from '../../assets/contactus/mail.png';

const Intro = () => {
  return (
    <div className="contact-wrapper">
      <div className="contact-hero">
        <h1>Contact Us</h1>
      </div>

      <div className="contact-detail">
        <div className="contact-container">
          <div className="contact-info">
            <div className="location">
              <img
                src={Location}
                alt="an icon showing location"
                className="locationImg"
              />
              <p className="contact-text">
                12 Clematis Cl, Red Lodge, Bury Saint Edmunds IP28 8JS, United
                Kingdom
              </p>
            </div>
            <div className="location">
              <img src={Mail} alt="an icon showing location" />
              <p className="contact-email">info@redfoxaccounting.co.uk</p>
            </div>
            {/* <h3 className='question-text'>Send us your questions and queries using the quick contact form below...</h3> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
