import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-nav">
        <h1>
          Red Fox
          <br />
          Accounting
        </h1>
        <ul className="links">
          <li className="footer-header">Site Map</li>
          <NavLink to="/" className="footer-links">
            <li className="links-nav">Home</li>
          </NavLink>
          <NavLink to="/about" className="footer-links">
            <li className="links-nav">About Us</li>
          </NavLink>
          <NavLink to="/services" className="footer-links">
            <li className="links-nav">Services</li>
          </NavLink>
          <NavLink to="/recommend" className="footer-links" hidden>
            <li className="links-nav">Testimonials</li>
          </NavLink>
          <NavLink to="/contact" className="footer-links">
            <li className="links-nav">Contact Us</li>
          </NavLink>
          <NavLink to="/faqs" className="footer-links" hidden>
            <li className="links-nav">FAQs</li>
          </NavLink>
        </ul>

        <ul className="links">
          <li className="footer-header">Quick Service Links</li>
          <NavLink to="/services/business" className="footer-links">
            <li className="links-nav">Business Start-up</li>
          </NavLink>
          <NavLink to="/services/compliance" className="footer-links">
            <li className="links-nav">Compliance Services</li>
          </NavLink>
          <NavLink to="/services/consultancy" className="footer-links">
            <li className="links-nav">Consultancy & systems advice</li>
          </NavLink>
          <NavLink to="/services/investment" className="footer-links">
            <li className="links-nav">Investment and Pensions</li>
          </NavLink>
          <NavLink to="/services/support" className="footer-links">
            <li className="links-nav">Support Services</li>
          </NavLink>
          <NavLink to="/services/taxation" className="footer-links">
            <li className="links-nav">Taxation</li>
          </NavLink>
        </ul>

        <div className="footer-info footer-column">
          <NavLink to="tel:01638 590463" className="number footer-links">
            01638 590463
          </NavLink>
          <br />
          <NavLink to="/" className="footer-links">
            <p className="mail">info@redfoxaccounting.co.uk</p>
          </NavLink>
          <p>
            Red Fox Accounting <br />
            12 Clematis Close <br /> Red Lodge <br />
            Burt St Edmunds <br />
            Suffolk <br />
            IP28 8JS
          </p>
        </div>
      </div>

      <hr className="footer-hr" />
      <div className="footer-row">
        <div className="footer-info">
          <NavLink to="tel:01638 590463" className="number footer-links">
            01638 590463
          </NavLink>
          <br />
          <NavLink to="/" className="footer-links">
            <p className="mail">info@redfoxaccounting.co.uk</p>
          </NavLink>
          <p>
            Red Fox Accounting <br />
            12 Clematis Close <br /> Red Lodge <br />
            Burt St Edmunds <br />
            Suffolk <br />
            IP28 8JS
          </p>
        </div>
      </div>

      <hr />
      <div className="footer-bottom">
        <p className="copyright">
          &copy; 2023. Red Fox Accounting. All Rights Reserved.{" "}
          <span className="footer-span">powered by</span>{" "}
          <a
            href="https://phasecurve.com"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-links"
          >
            Phasecurve
          </a>
        </p>
        <p className="cookies">
          We use cookies on this website, you can find more information about
          cookies here.
        </p>
      </div>
    </div>
  );
};

export default Footer;
