import React from 'react';
import { NavLink } from 'react-router-dom';
import { Fade } from 'react-reveal'

const GetInTouch = () => {
  return (
    <div className="get-in-touch">
      <div className='get-in-touch-wrapper'>
      <Fade left>
      <h3 className="get-in-touch-header">We would love to hear from you</h3>
      </Fade>
      <NavLink to="/contact">
      <Fade right>
        <button className="get-in-touch-button">Get in touch</button>
      </Fade>
      </NavLink>
      </div>
    </div>
  );
}

export default GetInTouch;
