import RecommendBg from '../../assets/svg/recommend-bg.svg';
import Cirle from '../../assets/svg/circle.svg';
import Ellipse from '../../assets/svg/ellipse.svg';
import Bottom from '../../assets/svg/bottom.svg';
import SpeechBubbles from '../CustomCard.tsx/SpeechBubbles';
import { RecommendationData } from '../../data/recommendations';

const Recommendations = () => {
  return (
    <section className="recommendation-container">
      <h2 className="recommend-text">
        Don’t take our word for it, here is some feedback from existing
        clients...
      </h2>
      <div className="recommendation-wrapper">
        <div className="recommendations">
          <div className="recommendations-card-wrapper">
            {RecommendationData.map((data, index) => (
              <div
                key={index}
                className="item"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: index % 2 === 0 ? 'flex-start' : 'flex-end',
                  // alignSelf: index % 2 === 0 ? 'flex-start' : 'flex-end',
                  width: '100%',
                  boxSizing: 'border-box',
                }}
              >
                <SpeechBubbles
                  name={data.name}
                  desc={data.desc}
                  company={data.company}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="redfox-text">
          <h1>REDFOX ACCOUNTING</h1>
        </div>
      </div>

      <div className="bottom-svg">
        <img src={Cirle} alt="" className="rec-circle-svg" />
        <img src={Ellipse} alt="" className="rec-eclispe-svg" />
      </div>
      {/* <div className="bottom-svg"></div> */}
    </section>
  );
};

export default Recommendations;
