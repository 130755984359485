// import Client from '../Components/About/Client';
import Founder from '../Components/About/Founder';
import Intro from '../Components/About/Intro';
import MissionVision from '../Components/About/MissionVision';
import Overview from '../Components/About/Overview';
import GetInTouch from '../Components/GetInTouch';
import Footer from '../Components/Layout/Footer';
import Header from '../Components/Layout/Header';

const AboutUs = () => {
  return (
    <div>
      <Header />
      <Intro />
      <Overview />
      <Founder />
      <MissionVision />
      {/* <Client /> */}
      <GetInTouch />
      <Footer />
    </div>
  );
};

export default AboutUs;
