import React from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import SEO from '../Seo';
import { useTypewriter } from 'react-simple-typewriter';
import bannerImage from '../../assets/home/bannerImage.webp';

const Intro = () => {
  const [accountingSubtext] = useTypewriter({
    words: ['Accounting for you'],
    loop: 1,
    typeSpeed: 70,
    delaySpeed: 3000,
  });

  return (
    <div>
      <SEO
        title="Red Fox Accounting"
        description="Red Fox Accounting is a firm of accountants offering accounts, 
        taxation and business advisory and support services 
        to a wide range of businesses and individuals."
        keywords="Red Fox Accounting, redfox accounting"
      />
      <div className="hero-wrapper">
        <div className="hero-container">
          <div className="hero-text">
            <div className="hero-content">
              <h1>Welcome to Red Fox</h1>
              <h4>{accountingSubtext}</h4>
              <hr className="hero-line" />
              <NavLink to="/about">
                <button className="home-hero-button hero-button">
                  Read More
                  <MdOutlineKeyboardArrowRight size="24px" className="icon" />
                </button>
              </NavLink>
            </div>
          </div>
          <div className="banner-container">
            <img src={bannerImage} alt="Banner" className="banner-image" />
          </div>
        </div>
      </div>
      {/* <div className="hero">
        <div className="hero-container">
          <h1 className="hero-text">
            Welcome to <br />
            <span className="hero-span">
              Red Fox <br />
              Accounting
            </span>
          </h1>
          <hr className="hero-line" />
          <p className="hero-subtext">{accountingSubtext}</p>
          <NavLink to="/about">
            <button className="hero-button">
              Read More
              <MdOutlineKeyboardArrowRight size="24px" className="icon" />
            </button>
          </NavLink>
        </div>
      </div> */}
    </div>
  );
};

export default Intro;
