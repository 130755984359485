import React from 'react'
import Header from '../Components/Layout/Header'
import Footer from '../Components/Layout/Footer'
import GetInTouch from '../Components/GetInTouch'
import ExploreInfo from '../Components/ExploreInfo'
import TaxationComp from '../Components/ServicePages/Taxation'
const Taxation = () => {
  return (
    <div>
        <Header/>
      <TaxationComp />
      <ExploreInfo />
        <GetInTouch/>
        <Footer/>
    </div>
  )
}

export default Taxation