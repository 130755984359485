import React from 'react';
import Header from '../Components/Layout/Header';
import Footer from '../Components/Layout/Footer';
import { Link } from 'react-router-dom';

const NoPage = () => {
  return (
    <>
      <Header />
      <div className="error-container">
        <h1>404 Not Found 😔</h1>
        <p>Sorry, the page you're looking for does not exist.</p>
        <div className="nopage-btn">
          <Link to="/" className="transparent-link">
            Go Back Home
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NoPage;
