import React, { FC } from 'react';
// import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

// type LatLngExpression = [number, number];

const ContactForm: FC = () => {
  const customIcon = new Icon({
    iconUrl: require('../../assets/contactus/map.png'),
    iconSize: [38, 38],
  });
  // const popupStyle: React.CSSProperties = {
  //   fontSize: '30px',
  //   background: '#000',
  // };
  return (
    <div>
      {/* <div className='contact-form'>
            <h1>Contact Form</h1>
            <form>
                <div className='form'>
                    <div className='form-name'>
                        <div className='firstName'>
                        <h3 className='form-label'>First Name:</h3>
                        <input type='text' placeholder='Enter your First Name' required/>
                        </div>
                        <div className='lastName'>
                            <h3 className='form-label'>Last Name:</h3>
                            <input type='text' placeholder='Enter your Last Name' required/>
                        </div>
                    </div>
                    <h3 className='form-label'>Telephone:</h3>
                    <input type='number' placeholder='e.g 01234*****'/>
                    <h3 className='form-label'>Email Address:</h3>
                    <input type='email' placeholder='Enter your email address' required/>
                    <h3 className='form-label'>Comments:</h3>
                    <textarea placeholder='Leave your comments...' ></textarea>
                    <Link to="/success">
                        <button className='contact-btn'>
                            Submit
                        </button>
                    </Link>
                </div>
            </form>
        </div> */}
      {/* <div className="map-outer">
        <div className="map_canvas">
          <iframe
            className="map_iframe"
            width="100%"
            height="600"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=12 Clematis Cl, Red Lodge,Bury Saint Edmunds IP28 8JS, United Kingdom&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div> */}

      <MapContainer
        // @ts-ignore
        center={[52.3035443, 0.4912307]}
        zoom={15}
        scrollWheelZoom={false}
        style={{ height: '500px', width: '100%' }}
      >
        <TileLayer
          // @ts-ignore
          attribution="&copy; OpenStreetMap contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker
          position={[52.3035443, 0.4912307]}
          // @ts-ignore
          icon={customIcon}
        >
          <Popup
            // @ts-ignore
            closeButton={false}
          >
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '1.6rem',
                color: '#a1151a',
              }}
            >
              Red Fox Accounting
            </span>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default ContactForm;
