import React from 'react'
import Header from '../Components/Layout/Header'
import Footer from '../Components/Layout/Footer'
import GetInTouch from '../Components/GetInTouch'
import ConsultancyComp from '../Components/ServicePages/Consultancy'
import ExploreInfo from '../Components/ExploreInfo'
const Consultancy = () => {
  return (
    <div>
      <Header />
      <ConsultancyComp />
      <ExploreInfo/>
      <GetInTouch />
      <Footer />
    </div>
  );
}

export default Consultancy