import React from 'react'
import Header from '../Components/Layout/Header'
import Footer from '../Components/Layout/Footer'
import GetInTouch from '../Components/GetInTouch'
import ComplianceServiceComp from '../Components/ServicePages/ComplianceService'
import ExploreInfo from "../Components/ExploreInfo";
const ComplianceService = () => {
  return (
    <div>
        <Header />
        <ComplianceServiceComp/>
        <ExploreInfo/>
        <GetInTouch />
        <Footer />
    </div>
  );
}

export default ComplianceService