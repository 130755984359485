import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

interface CardProps {
  image: string;
  title: string;
  description: string;
  buttonLink: string;
}
const ServiceCard: React.FC<CardProps> = ({
  image,
  title,
  description,
  buttonLink,
}) => {
  return (
    <div className="service-card">
      <img src={image} alt={title} className="service-image" />
      <div className="service-card-content">
        <h2 className="service-card-title">{title}</h2>
        <p className="service-card-description">{description}</p>
        <a href={buttonLink}>
          <div className="service-btn">
            <span>Read More</span>
            <BsArrowRight className="service-icon" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default ServiceCard;
