import Header from"../Components/Layout/Header";
import GetInTouch from "../Components/GetInTouch";
import Footer from "../Components/Layout/Footer";
import Overview from "../Components/Services/Overview";
import ServiceCards from "../Components/Services/ServiceCards";
import Intro from "../Components/Services/Intro";
const Services = () => {
    return (
      <div>
        <Header/>
        <Intro/>
        <Overview/>
        <ServiceCards/>
        <GetInTouch />
        <Footer />
      </div>
  );
}
     
        
export default Services;