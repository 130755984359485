import React from 'react'
import Header from '../Components/Layout/Header'
import Footer from '../Components/Layout/Footer'
import GetInTouch from '../Components/GetInTouch'
import InvestmentPensionComp from '../Components/ServicePages/InvestmentPension'
import ExploreInfo from '../Components/ExploreInfo'
const InvestmentPension = () => {
  return (
    <div>
      <Header />
      <InvestmentPensionComp/>
      <ExploreInfo/>
      <GetInTouch />
      <Footer />
    </div>
  );
}

export default InvestmentPension