import React from 'react';

const ComplianceService = () => {
  return (
    <div>
      <div className="compliance-hero">
        <h1>Compliance Services</h1>
      </div>
      <div className="overview">
        <div className="overview-container">
          <h2 className="overview-header">
            We provide a range of compliance services to support all aspects of
            business administration.
          </h2>
          <p className="overview-text">
            We can help you by making sure accounts are prepared in the correct
            format, statutory books and other company secretarial records are
            kept up-to-date and that all statutory returns are prepared. Our
            experienced team will relieve you of the regulatory burden and leave
            you more time to concentrate on your core business activities. In
            short, we will make sure everything is in the right format, in the
            right place and at the right time.
          </p>
          <p className="overview-text compliance-service-text">
            Our range of compliance services includes:
          </p>
          <ul className="about-li">
            <li> Annual accounts </li>
            <li>Specialist accounts and independent reviews</li>
            <li>
              Company secretarial services including confirmation statements
            </li>
            <li>
              Payroll procedures including Real Time Information and Pensions
              Auto Enrolment compliance
            </li>
            <li>Tax returns</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ComplianceService;
