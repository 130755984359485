import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const ExploreInfo = () => {
  return (
    <div>
      <div className="require-info-container ">
        <div className="explore-image-seg1"></div>
        <div className="explore-overlap-container">
          <h2>Require more information?</h2>
          <p>
            If you would like more information or would like to speak to us
            direct then call us on 01638 590463. Or if you would prefer,
            <NavLink to="/contact">
              <span className="resource-link"> ask us a question online.</span>
            </NavLink>
          </p>
        </div>
      </div>
      <div className="explore">
        <h2 className="explore-header">Explore our other services</h2>
      </div>
      <div className="explore-section-container">
        <Link to="/services/business">
          <div className="explore-section-a">
            <h3 className="explore-section-text">Business Start-up</h3>
          </div>
        </Link>
        <Link to="/services/investment">
          <div className="explore-section-b">
            <h3 className="explore-section-text">
              Investment &<br /> pensions
            </h3>
          </div>
        </Link>
        <Link to="/services/consultancy">
          <div className="explore-section-c">
            <h3 className="explore-section-text">
              Consultancy & <br />
              system advice
            </h3>
          </div>
        </Link>
        <Link to="/services/support">
          <div className="explore-section-d">
            <h3 className="explore-section-text">Support services</h3>
          </div>
        </Link>
        <Link to="/services/taxation">
          <div className="explore-section-e">
            <h3 className="explore-section-text">Taxation</h3>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ExploreInfo;
