import React from 'react';

const InvestmentPension = () => {
  return (
    <div>
      <div className="invest-hero">
        <h1>Investment and Pensions</h1>
      </div>
      <div className="overview">
        <div className="overview-container">
          <h2 className="overview-header">
            In today’s complicated and strictly regulated financial services
            market, providing independent financial advice requires specialist
            knowledge.
          </h2>
          <p className="overview-text">
            We make use of our wide range of professional contacts to ensure you
            have a suitable independent adviser and we work with them to provide
            you with impartial advice in areas such as:
          </p>
          <ul className="about-li">
            <li>Pension schemes / Auto Enrolment</li>
            <li>Residential, commercial and buy to let mortgages</li>
            <li>Home and landlords insurance</li>
            <li>Life insurance</li>
            <li>Retirement planning</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InvestmentPension;
