import React from 'react'
import RecommendationsComp from '../Components/Recommendations/Recommendations'
import Footer from '../Components/Layout/Footer'
import Header from '../Components/Layout/Header'
import Intro from '../Components/Recommendations/Intro'

const Recommendations = () => {
  return (
    <div>
      <Header/>
      <Intro/>
      <RecommendationsComp />
      <Footer/>
    </div>
  )
}

export default Recommendations
