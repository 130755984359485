import React, { FC } from 'react'

type Props = {
  desc: string;
  name: string;
  company: string;
};

const SpeechBubbles: FC<Props> = ({ desc, name, company }) => {
  return (
    <div className="recommend-card">
      <p className="recommend-desc">{desc}</p>
      <ul>
        <li className="recommend-name">
          {name}<br/>
        <span className='recommend-comp'>{company}</span>
        </li>
      </ul>
    </div>
  );
};

export default SpeechBubbles
