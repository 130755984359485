export const RecommendationData = [
  {
    desc: "Clare saved my life at the end of last year - well, my sanity at any rate!!My business accounts were in some disarray and she helped with advice and some number crunching to allow me to get my end of year accounts in to HMRC. At that point we hadn't even agreed that she would look after my accounts in future, but that didn't stop her from giving me some (essential!) help.",
    name: "Michael,",
    company: "Newmarket Community Services Ltd",
  },
  {
    desc: "I’ve used red fox accounting for a few years now and cannot recommend enough",
    name: "Mark,",
    company: "MH Carpentry",
  },
  {
    desc: "Clare has been a godsend these past 6 years. Whenever I have needed advice on the phone she would be for however long it took.  ",
    name: "Emma P",
    company: "",
  },
  {
    desc: "I have known and worked with Clare for many years and over that time her experience and expertise has grown enormously. She is up to date on all matters relating to her profession, reliable, efficient, attentive to detail as one would expect from an accountant, kind and thoughtful in how she does her work. I would not hesitate to recommend her to anyone as I know with certainty they will not be disappointed. I cannot recommend her highly enough, as is evidenced by my friends and colleagues who now have used her service, on my recommendation, can testify.",
    name: "Kerstin M",
    company: "",
  },
  {
    desc: "What can I say about Clare at Red Fox Accounting. Since I first met her she has been nothing but professional, articulate and driven to provide the best possible service I have always appreciated the swift responses and advice given, even on weekends, when I’ve had a query or needed help with something. Its been a pleasure to work with Clare and she has really helped with my business and ensuring my finances are all in order – one less thing to worry about! Thanks so much for your hard work and dedication – keep up the fantastic work!",
    name: "Alex,",
    company: "Suffolk Business Directory",
  },
  {
    desc: "Clare manages the payroll for me and does so in a most efficient and professional way.  If you have a query she responds promptly and is very knowledgeable.  Compared with my previous payroll provider there is no comparison.  An excellent service and very highly recommended",
    name: "Bridget F",
    company: "",
  },
  {
    desc: "I’ve used Clare for my tax returns for a few years now, very friendly, professional and above all else, patient service!  She is very proactive in reminding me to make the return in good time and ready with advice on how to make it as painless as possible.",
    name: "Anon",
    company: "",
  },
  {
    desc: "'Clare is an excellent accountant and has completed my self assessments efficiently and quickly over the last few years. She is also brilliant at responding to queries throughout the year. She absolutely goes the extra mile and sends out a very useful newsletter which has been especially helpful during pandemic. I cannot recommend her highly enough'",
    name: "Katherine S",
    company: "",
  },
  {
    desc: "Clare has provided her services to us for several years. She is knowledgeable and always informative about any issues. I have no hesitation in recommending Clare and her services she provides to anybody looking for an accountant.",
    name: "Gail A",
    company: "",
  },
  {
    desc: "I was ‘enduring’ an unsatisfactory relationship with an Accountancy firm and Red Fox Accounting were recommended to me.  What a relief it was to find Clare and a pleasure to work with someone who really knows what she is doing.  Professional, efficient and responsive and a great person to work with.",
    name: "Nicky,",
    company: "DeNecy Ltd",
  },
  {
    desc: "Want to thank Clare for absolutely everything. From day 1 she has been a rock to our business with the work she has done and continues to do so. <br/>Keep it up Clare",
    name: "Geeta,",
    company: " RWS Building & Landscapes Ltd",
  },
  {
    desc: "Clare at Red Fox, what can I say – she is brilliant. She obviously knows her stuff and is extremely thorough and clear in her approach. Really happy to have her doing my accounts and would recommend her to anyone.",
    name: "Alex R",
    company: "",
  },
  {
    desc: "Clare is a great accountant – knowledgable, friendly & reliable. Highly recommend.",
    name: "Sara N",
    company: "",
  },
  {
    desc: "Clare has done our books for several years, she has always been helpful and thorough. I wouldn’t hesitate to recommend Clare for the service she provides.",
    name: "Pat C",
    company: "",
  },
  {
    desc: "I have known Clare for 7 years and during this period Clare has provided us with excellent advice and constant support towards our personal and business accounts. Clare is prompt, thorough, approachable  and very balanced as a professional and I am very grateful to her time and help with our practice.",
    name: "Madhavan,",
    company: "Cambridge Vision Clinic Ltd",
  },
  {
    desc: "Clare is absolutely the best accountant around! She is quick, helpful, knowledgeable, polite and friendly. Any questions you have are never too much trouble and she is always quick to help when she can! Her knowledge is amazing and has made mine and my partners lives so much easier running our small businesses! I would not go anywhere else now, 100% recommend Clare and her services!",
    name: "Lucie C",
    company: "",
  },
  {
    desc: "Clare at Red Fox Accounting has been fantastic, she always makes time to answer my questions, no matter how big or small, i started my business two years ago and Clare has been a total godsend with her knowledge and attitude towards helping me understand my own business finances.",
    name: "Carlie,",
    company: "Buck & Bear",
  },
  {
    desc: "Clare took over the accounts for a small community property management company I manage just a few years ago, easily transitioning from the previous mainstream accounting company. When I started my own company I just took it straight to Clare and she took all the accounting pain away. No hesitation in recommending her.",
    name: "Martin S",
    company: "",
  },
  {
    desc: "Clare has been our accountant for several years now. We've always put her to the test by having more than one business and always having many questions for her. She is very knowledgeable and patient and explains things confidently but in a friendly manner that makes us feel at ease. Professional and friendly, service with a smile, what more could you ask for.",
    name: "Polly H",
    company: "",
  },
  {
    desc: "Clare has been looking after both our company and personal financial accounts for over 15 years and I would not have anyone else do so.  Clare has provided a very professional service at all times and her advice, guidance and knowledge has been invaluable. She is a very safe pair of hands and I have absolute confidence in her advice and knowledge, especially in relation to any tax matters. Not only does Clare understand the issues of business and tax but she provides her clients with a personal service which warm, friendly, efficient and honest. You definitely feel you have someone in your corner. Can’t recommend her highly enough.",
    name: "Pat P and Maureen S",
    company: "",
  },
  {
    desc: "I hugely value your kind and professional service.  Even as a small client you always reply to messages promptly and give me a great service.",
    name: "Roz R",
    company: "",
  },
  {
    desc: "Red Fox Accounting provides a brilliant service and I get good value for money. I always get a quick reply. Highly recommended!",
    name: "Monica K",
    company: "",
  },
  {
    desc: "I have been very pleased with the work that Clare has done for me and her guidance and help. It is an area I do not have  a lot of knowledge in so very pleased.",
    name: "Sue D",
    company: "",
  },
  {
    desc: "Clare has been excellent the last 18 months we have been using her. Always available if we have any queries and nothing is ever too much trouble. Definitely a massive part of the running of LJM.",
    name: "Lee M,",
    company: "LJM Landscaping",
  },
  {
    desc: "Brilliant service literally keeps me ticking like clockwork….no stress so I can just do my work.",
    name: "Nat C, ",
    company: "NJB Groundworks",
  },
  {
    desc: "Clare has been my accountant for about 5 years. Always ahead of what needs doing and submitting documents. Has been a great help over the years, I can’t thank her enough! I have recommended friends, who are also very pleased with her work.  Excellent! ",
    name: "Barry D,",
    company: " BT Flooring Ltd",
  },
  {
    desc: "Clare is truly fantastic for our small business. Its no secret that we aren’t the experts in accounting, but so privileged to have Clare on hand to help us through our financial needs, to answer our questions big or small, with a friendly and warm approach.",
    name: "Charlie and Alex,",
    company: "Peak Performance Reviews Ltd",
  },
  {
    desc: "We are so pleased Clare is our Accountant an amazing lady always willing to help, so knowledgeable and caring too! she has been absolutely amazing with information throughout the covid period we would be lost without her!",
    name: "Jumppin Jacks Ltd",
    company: "",
  },
];
