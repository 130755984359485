
const Intro = () => {
  return (
    <div>
      <div className="recommend-hero">
        <div className="recommend-wrapper">
          <h1>Recommendations</h1>
        </div>
      </div>
    </div>
  );
}

export default Intro
