import React from 'react'
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const SuccessPage = () => {
  return (
    <div className='success-container'>
      <h2>Form submitted successfully </h2>
      <p className='success-text'>Thank you for contacting us</p>
      <Link to="/">
        <div className='success-page-link'>
            <FaHome size='30px' color='#A1151A'/>
            <p>Back to Home</p>
        </div>
      </Link>
    </div>
  )
}

export default SuccessPage
