import Header from '../Components/Layout/Header';
import Footer from '../Components/Layout/Footer';
import Intro from '../Components/Contact/Intro';
import ContactForm from '../Components/Contact/ContactForm';
// import Faqs from '../Components/Contact/Faqs';

const ContactUs = () => {
  return (
    <div>
      <Header />
      <Intro />
      <ContactForm />
      {/* <Faqs /> */}
      <Footer />
    </div>
  );
};

export default ContactUs;
