import React from 'react'
import Header from '../Components/Layout/Header'
import Footer from '../Components/Layout/Footer'
import GetInTouch from '../Components/GetInTouch'
import ExploreInfo from '../Components/ExploreInfo'
import SupportServiceComp from '../Components/ServicePages/SupportService'

const SupportService = () => {
  return (
    <div>
       <Header/>
       <SupportServiceComp/>
       <ExploreInfo/>
        <GetInTouch/>
       <Footer/>
    </div>
  )
}

export default SupportService