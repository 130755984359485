import React from 'react';
import Fact from '../../assets/home/factSheet.png';
import Resource from '../../assets/home/resources.png';
import { Link, NavLink } from 'react-router-dom';
import { Bounce, Fade, Zoom } from 'react-reveal';
import Consultancy from './../../pages/Consultancy';

const HomeSection = () => {
  return (
    <div>
      <div className="section-container">
        <Link to="/services/business">
          <div className="section-a">
            <Zoom duration={1500}>
              <h3 className="section-text">Business Start-up</h3>
            </Zoom>
          </div>
        </Link>
        <Link to="/services/consultancy">
          <div className="section-b">
            <Zoom duration={1500}>
              <h3 className="section-text">Consultancy</h3>
            </Zoom>
          </div>
        </Link>
        <Link to="/services/compliance">
          <div className="section-c">
            <Zoom duration={1500}>
              <h3 className="section-text">Compliance Services</h3>
            </Zoom>
          </div>
        </Link>
        <Link to="/services/taxation">
          <div className="section-d">
            <Zoom duration={1500}>
              <h3 className="section-text">Taxation</h3>
            </Zoom>
          </div>
        </Link>
      </div>

      <div className="count-on-us">
        <hr />
        <Bounce>
          <h3>
            Count on us for our expertise;
            <br /> stay with us for the reliability
          </h3>
        </Bounce>
        <hr />
      </div>
      <div className="resources">
        <img src={Resource} alt="" />
        <div className="resources-container">
          <Fade duration={2000}>
            <h3>Resource</h3>
          </Fade>
          <p>
            Access to really useful resources including: calculators, tax rates
            and allowances, market data, a tax calendar together with access to
            HMRC and Companies House forms.
          </p>
          <NavLink to="/resource" hidden>
            <p className="resource-link">Check out our business resources</p>
          </NavLink>
        </div>
      </div>
      <div className="resources fact-flip">
        <div className="resources-container flip">
          <Fade duration={2000}>
            <h3>Fact Sheet</h3>
          </Fade>
          <p>
            Fact sheets covering a range of relevant accountancy and business
            topics.
          </p>
          <NavLink to="/factsheet" hidden>
            <p className="resource-link">Learn more</p>
          </NavLink>
        </div>
        <img src={Fact} alt="" />
      </div>
    </div>
  );
};

export default HomeSection;
