import React from 'react';
import { Bounce } from 'react-reveal';

const Overview = () => {
  return (
    <>
      <div className="overview">
        <div className="overview-container">
          <h2 className="overview-header">
            Accounting Solutions You Can Count On
          </h2>
          <p className="overview-text">
            Leave your financial worries to us! At Red Fox Accounting, our
            dedicated team of tax and accounting professionals ensures you have
            peace of mind. With our cost-effective solutions, managing your
            finances becomes hassle free. Get an accurate picture of your
            financials and make informed decisions about your future with our
            expert assistance.
          </p>
        </div>
      </div>
      <div className="service-banner">
        <Bounce>
          <h2>OUR SERVICES</h2>
        </Bounce>
      </div>
      <div className="service-writeup-wrapper">
        <p className="overview-text service-writeup">
          We offer a full range of services that spans across accounting,
          consulting, and wealth management.
        </p>
      </div>
    </>
  );
};

export default Overview;
