import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import Services from './pages/Services';
import SupportService from './pages/SupportService';
import InvestmentPension from './pages/InvestmentPension';
import ComplianceService from './pages/ComplianceService';
import Consultancy from './pages/Consultancy';
import BusinessStartUp from './pages/BusinessStartUp';
import Taxation from './pages/Taxation';
// import Resource from './pages/Resource';
// import FactSheet from './pages/FactSheet';
// import AccSend from './pages/AccSend';
// import DownloadableForms from './pages/DownloadableForms';
// import MarketData from './pages/MarketData';
// import OnlineCalucator from './pages/OnlineCalucator';
// import TaxCalender from './pages/TaxCalender';
// import TaxRates from './pages/TaxRates';
// import UsefulLink from './pages/UsefulLink';
// import CapitalTax from './pages/CapitalTax';
// import CorporateTax from './pages/CorporateTax';
// import BusinessStartUpTax from './pages/BusinessStartUpTax';
// import EmploymentIssuesTax from './pages/EmploymentIssuesTax';
// import EmploymentTax from './pages/EmploymentTax';
// import GeneralBusinessTax from './pages/GeneralBusinessTax';
// import Ict from './pages/Ict';
// import Pensions from './pages/Pensions';
// import PersonalTax from './pages/PersonalTax';
// import SpecialistAreas from './pages/SpecialistAreas';
// import Vat from './pages/Vat';
import ScrollToTop from './Components/ScrollToTop';
import SuccessPage from './pages/SuccessPage';
import Nopage from './pages/Nopage';
import Recommendations from './pages/Recommendations';
// import HMRC from './pages/HMRC';
// import CompanyHouse from './pages/CompanyHouse';
// import { RetailPrice } from './pages/RetailPrice';
// import { InterestRatePage } from './pages/InterestRatePage';
// import FaqsPage from './pages/FaqsPage';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        {/* <Route path="/faqs" element={<FaqsPage />} /> */}
        <Route path="/services/support" element={<SupportService />} />
        <Route path="/services/investment" element={<InvestmentPension />} />
        <Route path="/services/compliance" element={<ComplianceService />} />
        <Route path="/services/consultancy" element={<Consultancy />} />
        <Route path="/services/business" element={<BusinessStartUp />} />
        <Route path="/services/taxation" element={<Taxation />} />
        <Route path="/testimonials" element={<Recommendations />} />
        <Route path="*" element={<Nopage />} />
        {/* <Route path="/resource" element={<Resource />} /> */}
        {/* <Route path="/factsheet" element={<FactSheet />} /> */}
        {/* <Route path="/accsend" element={<AccSend />} />
        <Route path="/forms" element={<DownloadableForms />} />
        <Route path="/company" element={<CompanyHouse />} />
        <Route path="/hmrc" element={<HMRC />} />
        <Route path="/data" element={<MarketData />} />
        <Route path="/calculators" element={<OnlineCalucator />} />
        <Route path="/calendar" element={<TaxCalender />} />
        <Route path="/rates" element={<TaxRates />} />
        <Route path="/uselinks" element={<UsefulLink />} />
        <Route path="/capital" element={<CapitalTax />} />
        <Route path="/corporate" element={<CorporateTax />} />
        <Route path="/startup" element={<BusinessStartUpTax />} />
        <Route path="/employ-issue" element={<EmploymentIssuesTax />} />
        <Route path="/employment" element={<EmploymentTax />} />
        <Route path="/general-business-tax" element={<GeneralBusinessTax />} />
        <Route path="/ict" element={<Ict />} />
        <Route path="/pensions" element={<Pensions />} />
        <Route path="/personal" element={<PersonalTax />} />
        <Route path="/specialist" element={<SpecialistAreas />} />
        <Route path="/vat" element={<Vat />} />
        <Route path="/retail-price" element={<RetailPrice />} />
        <Route path="/interest-rates" element={<InterestRatePage />} /> */}
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
