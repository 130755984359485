import React from 'react';
import twoMenLaptop from '../../assets/home/twoMen.png';
import { Bounce, Fade } from 'react-reveal';
const Overview = () => {
  return (
    <>
      <div className="overview">
        <div className="overview-container">
          <h2 className="overview-header">
            Accurate financials and <br className="overview-br" />
            total peace of mind
          </h2>
          <p className="overview-text">
            At Red Fox Accounting we don’t believe in a one size fits all
            approach. Our services are tailored to meet your requirements,
            whether that’s a tax return for your rental property to VAT, payroll
            and accounts for your growing Limited company. Unlike larger firms,
            we work on a one to one basis so you will have one point of contact
            for all services, whether it’s a quick payroll question, or a full
            set of management accounts. This provides us with a full view of
            your business and tax affairs, enabling us to provide you with the
            best advice for any scenario.
          </p>
        </div>
      </div>
      <div className="mid-view">
        <Fade Left>
          <div className="mid-view-text">
            <div className="mid-view-span-text">
              <h3>
                <span>"</span>
                Helping our clients understand their accounts
                <span>"</span>
              </h3>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="mid-view-img">
            <img src={twoMenLaptop} alt="" />
          </div>
        </Fade>
      </div>
      <div className="home-what-we-do">
        <Bounce>
          <h1>WHAT WE DO</h1>
        </Bounce>
        <hr />
        <p>
          Our accounting services are designed to look after small-business
          financials so owners can focus on what they do best.
        </p>
      </div>
    </>
  );
};

export default Overview;
