import React from 'react'
import Header from '../Components/Layout/Header'
import Footer from '../Components/Layout/Footer'
import GetInTouch from '../Components/GetInTouch'
import ExploreInfo from "../Components/ExploreInfo";
import BusinessStartUpComp from "../Components/ServicePages/BusinessStartUp";
const BusinessStartUp = () => {
  return (
    <div>
        <Header /> 
        <BusinessStartUpComp/>
        <ExploreInfo/>
        <GetInTouch />
        <Footer />
    </div>
  );
}

export default BusinessStartUp