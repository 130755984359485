import React from 'react'

const Intro = () => {
  return (
    <div>
      <div className='about-hero'>
        <div className='about-wrapper'>
        <h1 >About Us</h1>
        </div>
      </div>
    </div>
  )
}

export default Intro
